@import "../../scss/variables";

a:hover {
    color: $primary;
    text-decoration: none;
  }
  
  .table-primary {
    background-color: #e7effb;
  
    > {
      th, td {
        background-color: #e7effb;
      }
    }
  
    th, td, thead th, tbody + tbody {
      border-color: #e7effb;
    }
  }
  
  .table-hover .table-primary:hover {
    background-color: #e7effb;
  
    > {
      td, th {
        background-color: #e7effb;
      }
    }
  }
  
  .btn-primary {
    color: $white;
    background-color: $primary;
    border-color: $primary;
  
    &:hover {
      color: $white;
      background-color: #003df2;
      border-color: #003df2;
    }
  
    &:focus, &.focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 104, 255, 0.2);
      background-color: $primary !important;
      border-color: $primary !important;
    }
  
    &.disabled, &:disabled {
      color: $white;
      background-color: $primary;
      border-color: $primary;
    }
  
    &:not(:disabled):not(.disabled) {
      &:active, &.active {
        color: $white;
        background-color: $primary;
        border-color: $primary;
      }
    }
  }
  
  .show > .btn-primary.dropdown-toggle {
    color: $white;
    background-color: $primary;
    border-color: $primary;
  }
  
  .btn-primary:not(:disabled):not(.disabled) {
    &:active:focus, &.active:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 104, 255, 0.2);
    }
  }
  
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 104, 255, 0.2);
  }
  
  .btn-outline-primary {
    color: $primary;
    border-color: $primary;
  
    &:hover {
      color: $white;
      background-color: #003df2 !important;
      border-color: #003df2;
    }
  
    &:focus, &.focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 104, 255, 0.5);
      background-color: #003df2 !important;
    }
  
    &.disabled, &:disabled {
      color: $primary;
      background-color: transparent;
    }
  
    &:not(:disabled):not(.disabled) {
      &:active, &.active {
        color: $white;
        background-color: $primary;
        border-color: $primary;
      }
    }
  }
  
  .show > .btn-outline-primary.dropdown-toggle {
    color: $white;
    background-color: $primary;
    border-color: $primary;
  }
  
  .btn-outline-primary:not(:disabled):not(.disabled) {
    &:active:focus, &.active:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 104, 255, 0.5);
    }
  }
  
  .show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 104, 255, 0.5);
  }
  
  .dropdown-item {
    &.active, &:active {
      color: $white;
      text-decoration: none;
      background-color: $primary;
    }
  }
  
  .custom-control-input {
    &:checked ~ .custom-control-label::before {
      color: $white;
      border-color: $primary;
      background-color: $primary;
    }
  
    &:focus {
      ~ .custom-control-label::before {
        box-shadow: 0 0 0 0.2rem rgba(0, 104, 255, 0.25);
      }
  
      &:not(:checked) ~ .custom-control-label::before {
        border-color: #e7effb;
      }
    }
  
    &:not(:disabled):active ~ .custom-control-label::before {
      color: $white;
      background-color: #e7effb;
      border-color: #e7effb;
    }
  }
  
  .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
    border-color: $primary;
    background-color: $primary;
  }
  
  .custom-range {
    &::-webkit-slider-thumb {
      background-color: $primary;
    }
  
    &::-moz-range-thumb, &::-ms-thumb {
      background-color: $primary;
  
      &:active {
        background-color: #e7effb;
      }
    }
  }
  
  .nav-pills {
    .nav-link.active, .show > .nav-link {
      color: $white;
      background-color: $primary;
    }
  }
  
  @media (max-width: 767.98px) {
    .underline-nav .main-nav-line .nav-link.active::before {
      background-color: $primary;
    }
  }
  
  @media (min-width: 768px) {
    .underline-nav .main-nav-line .nav-link.active::before {
      background-color: $primary;
    }
  }
  
  .card.card-primary {
    border-top: 2px solid $primary !important;
  }
  
  .page-link {
    color: $primary;
  }
  
  .page-item.active .page-link {
    z-index: 1;
    color: $white;
    background-color: $primary;
    border-color: $primary;
  }
  
  .progress-bar {
    background-color: $primary;
  }
  
  .list-group-item.active {
    z-index: 2;
    color: $white;
    background-color: $primary;
    border-color: $primary;
  }
  
  .bg-primary {
    background-color: $primary !important;
  }
  
  a.bg-primary {
    &:hover, &:focus {
      background-color: #003df2 !important;
    }
  }
  
  button.bg-primary {
    &:hover, &:focus {
      background-color: #003df2 !important;
    }
  }
  
  .text-primary {
    color: $primary !important;
  }
  
  a.text-primary {
    &:hover, &:focus {
      color: #003df2 !important;
    }
  }
  
  .accordion-indigo {
    .card {
      border-color: $primary;
    }
  
    .card-header a {
      color: $primary;
  
      &:hover, &:focus {
        color: $primary;
      }
  
      &.collapsed {
        background-color: $primary;
        color: rgba(255, 255, 255, 0.8);
  
        &:hover, &:focus {
          background-color: $primary;
          color: $white;
        }
      }
    }
  }
  
  .accordion-blue {
    .card {
      border-color: $primary;
    }
  
    .card-header a {
      color: $primary;
  
      &:hover, &:focus {
        color: $primary;
      }
  
      &.collapsed {
        background-color: $primary;
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }
  
  .main-accordion .accordion-item.active .accordion-title {
    color: $primary;
  }
  
  .accordion.accordion-color .card-header a.collapsed {
    color: $white;
    background-color: $primary;
    border-bottom-color: transparent;
  
    &:hover, &:focus {
      color: $white;
      background-color: $primary;
    }
  }
  
  /*////////////////////badgelight//////////////////*/
  
  .badge-primary-transparent {
    color: $primary;
    background-color: #d9e8fe;
  }
  
  .badge-primary {
    color: $white;
    background-color: $primary;
  }
  
  a.badge-primary {
    &:hover {
      color: $white;
      background-color: #003df2;
    }
  
    &:focus {
      color: $white;
      background-color: #003df2;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 104, 255, 0.5);
    }
  
    &.focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 104, 255, 0.5);
    }
  }
  
  .breadcrumb-style1 .breadcrumb-item a {
    &:hover, &:focus {
      color: $primary;
    }
  }
  
  .breadcrumb-style2 .breadcrumb-item a {
    &:hover, &:focus {
      color: $primary;
    }
  }
  
  .breadcrumb-style3 .breadcrumb-item a {
    &:hover, &:focus {
      color: $primary;
    }
  }
  
  .breadcrumb-style1 .breadcrumb-item.active, .breadcrumb-style2 .breadcrumb-item.active, .breadcrumb-style3 .breadcrumb-item.active, .main-breadcrumbs .breadcrumb-item.active {
    color: $primary;
  }
  
  .breadcrumb5 li {
    &:first-child {
      background: $primary;
    }
  
    &.active, a {
      color: $primary;
    }
  }
  
  .breadcrumb6 li {
    &:first-child {
      background: $primary;
    }
  
    &.active, a {
      color: $primary;
    }
  }
  
  .breadcrumb7 li {
    &:after, a {
      background: $primary;
    }
  
    &:last-child {
      color: $primary;
    }
  }
  
  .breadcrumb8 li {
    &:after, a {
      background: $primary;
    }
  
    &:last-child {
      color: $primary;
    }
  }
  
  .breadcrumb9 li:last-child {
    background: $primary;
    color: $white;
  
    &:after {
      border-left-color: $primary;
    }
  }
  
  .btn-outline-indigo {
    border-color: $primary;
    color: $primary;
  
    &:hover, &:focus {
      background-color: $primary;
    }
  }
  
  /* ###### 5.1 Buttons  ###### */
  
  .btn-main-primary {
    color: $white;
    background-color: $primary;
    border-color: $primary;
  
    &:hover {
      color: $white;
      background-color: #003df2;
      border-color: #003df2;
    }
  
    &:focus, &.focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 104, 255, 0.2);
    }
  
    &.disabled, &:disabled {
      color: $white;
      background-color: $primary;
      border-color: $primary;
    }
  
    &:not(:disabled):not(.disabled) {
      &:active, &.active {
        color: $white;
        background-color: $primary;
        border-color: $primary;
      }
    }
  }
  
  .show > .btn-main-primary.dropdown-toggle {
    color: $white;
    background-color: $primary;
    border-color: $primary;
  }
  
  .btn-main-primary:not(:disabled):not(.disabled) {
    &:active:focus, &.active:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 104, 255, 0.2);
    }
  }
  
  .show > .btn-main-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 104, 255, 0.2);
  }
  
  .modal-content .close, .simple-modal .close {
    color: $primary;
  }
  
  .nav-pills .nav-link.active {
    background-color: $primary;
  }
  
  .main-nav .nav-link.active {
    color: $primary;
  }
  
  .main-nav-column .nav-link.active {
    color: $primary;
  
    &::before {
      background-color: $primary;
    }
  
    &:hover, &:focus, i, &:hover i, &:focus i {
      color: $primary;
    }
  }
  
  .main-nav-dark .nav-link.active {
    color: $primary;
  }
  
  .pagination-dark .page-item.active .page-link {
    background-color: $primary;
    color: $white;
  }
  
  .pagination-primary .page-item.active .page-link {
    background-color: $primary;
  }
  
  .pagination-radius li a:hover {
    color: $white;
    background-color: $primary;
  }
  
  .page-item.active .page-link {
    background-color: $primary;
  }
  
  .popover-head-primary {
    .popover-header {
      color: $white;
      background-color: $primary;
    }
  
    &.bs-popover-bottom .arrow::after, &.bs-popover-auto[x-placement^="bottom"] .arrow::after {
      border-bottom-color: $primary;
    }
  }
  
  .popover-head-secondary {
    .popover-header {
      color: $white;
      background-color: $primary;
    }
  
    &.bs-popover-bottom .arrow::after, &.bs-popover-auto[x-placement^="bottom"] .arrow::after {
      border-bottom-color: $primary;
    }
  }
  
  .popover-primary {
    background-color: $primary !important;
    border: 1px solid $primary !important;
  
    &.bs-popover-top .arrow::after, &.bs-popover-auto[x-placement^="top"] .arrow::after {
      border-top-color: $primary;
    }
  
    &.bs-popover-bottom .arrow::after, &.bs-popover-auto[x-placement^="bottom"] .arrow::after {
      border-bottom-color: $primary;
    }
  
    &.bs-popover-left .arrow::after, &.bs-popover-auto[x-placement^="left"] .arrow::after {
      border-left-color: $primary;
    }
  
    &.bs-popover-right .arrow::after, &.bs-popover-auto[x-placement^="right"] .arrow::after {
      border-right-color: $primary;
    }
  }
  
  .popover-secondary {
    background-color: $primary !important;
    border: 1px solid $primary !important;
  
    &.bs-popover-top .arrow::after, &.bs-popover-auto[x-placement^="top"] .arrow::after {
      border-top-color: $primary;
    }
  
    &.bs-popover-bottom .arrow::after, &.bs-popover-auto[x-placement^="bottom"] .arrow::after {
      border-bottom-color: $primary;
    }
  
    &.bs-popover-left .arrow::after, &.bs-popover-auto[x-placement^="left"] .arrow::after {
      border-left-color: $primary;
    }
  
    &.bs-popover-right .arrow::after, &.bs-popover-auto[x-placement^="right"] .arrow::after {
      border-right-color: $primary;
    }
  }
  
  .card-dashboard-progress .progress-legend li:first-child::before {
    background-color: $primary;
  }
  
  .dataTables_wrapper .dataTables_paginate .paginate_button.current {
    background-color: $primary;
    color: $white !important;
  
    &:hover, &:focus {
      background-color: $primary;
      color: $white !important;
    }
  }
  
  .tooltip-primary {
    .tooltip-inner {
      background-color: $primary;
      color: $white;
    }
  
    &.bs-tooltip-top .arrow::before, &.bs-tooltip-auto[x-placement^="top"] .arrow::before {
      border-top-color: $primary;
    }
  
    &.bs-tooltip-bottom .arrow::before, &.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      border-bottom-color: $primary;
    }
  
    &.bs-tooltip-left .arrow::before, &.bs-tooltip-auto[x-placement^="left"] .arrow::before {
      border-left-color: $primary;
    }
  
    &.bs-tooltip-right .arrow::before, &.bs-tooltip-auto[x-placement^="right"] .arrow::before {
      border-right-color: $primary;
    }
  }
  
  /* ###### Tooltip  ###### */
  /* ###### Colors  ###### */
  
  .slide-menu a.active {
    background: transparent;
    color: $primary !important;
  }
  
  .slide.submenu.is-expanded .side-menu__item {
    color: $primary !important;
  }
  
  .slide-item {
    &.active, &:hover, &:focus {
      color: $primary;
      background: transparent;
    }
  }
  
  .first-sidemenu, .slide-item.active:before, .slide.submenu.active:before {
    background: $primary;
  }
  
  .irs-min, .irs-max, .tree li i {
    color: $primary;
  }
  
  .step-app > ul > li .number .cart-icons {
    fill: $primary;
  }
  
  .main-content-breadcrumb span:last-child {
    color: $primary !important;
  }
  
  .main-content-left-components .component-item .nav-link {
    &:hover, &.active {
      color: $primary;
    }
  }
  
  .main-content-choose-demo .title-label {
    color: $primary;
  }
  
  .main-logo {
    &:hover, &:focus {
      color: $primary;
    }
  }
  
  .main-header-menu .nav-item {
    > .nav-link:hover {
      color: $primary;
  
      i {
        color: $primary;
      }
    }
  
    &.active > .nav-link {
      color: $primary;
  
      &::before {
        content: '';
        background-color: $primary;
      }
    }
  }
  
  .main-menu-sub .nav-link {
    &:hover, &:focus, &.active {
      color: $primary;
    }
  }
  
  .main-header-right .btn-buy {
    background-color: $primary;
  
    &:hover, &:focus {
      background-color: $primary;
    }
  }
  
  .main-profile-menu > .profile-name::after {
    border-top-color: $primary;
    border-left-color: $primary;
    background-color: $primary;
  }
  
  .main-header-primary {
    background-color: $primary;
  
    .main-header-center {
      .form-control {
        background-color: $primary;
        color: $white;
  
        &::placeholder {
          color: rgba(255, 255, 255, 0.4);
        }
  
        &:focus + .btn {
          color: $white;
        }
      }
  
      .sp-container .form-control:focus + button {
        color: $white;
      }
    }
  }
  
  .sp-container .main-header-primary .main-header-center .form-control:focus + button {
    color: $white;
  }
  
  .main-header-primary .main-header-center {
    .btn:hover, .sp-container button:hover {
      color: $white;
    }
  }
  
  .sp-container .main-header-primary .main-header-center button:hover {
    color: $white;
  }
  
  .main-header-primary .main-header-center {
    .btn:focus, .sp-container button:focus {
      color: $white;
    }
  }
  
  .sp-container .main-header-primary .main-header-center button:focus {
    color: $white;
  }
  
  .main-header-primary {
    .main-logo {
      color: $white;
  
      span {
        color: rgba(255, 255, 255, 0.65);
      }
    }
  
    .main-header-menu-icon span {
      background-color: rgba(255, 255, 255, 0.65);
  
      &::before, &::after {
        background-color: rgba(255, 255, 255, 0.65);
      }
    }
  
    .main-header-message > a, .main-header-notification > a {
      color: rgba(255, 255, 255, 0.75);
    }
  }
  
  .main-header .input-group-btn .btn:hover {
    color: $primary;
  }
  
  .heading-primary {
    background-color: $primary;
    color: $white;
  }
  
  .main-sidebar-body .nav-sub-item.active > .nav-sub-link {
    color: $primary;
  }
  
  .profile-page .profile-header .header-links ul li {
    &:hover, &.active, &:hover a, &.active a {
      color: $primary;
    }
  }
  
  .nav.nav-contrast.nav-tabs > .nav-link {
    &.active > a {
      color: $primary;
      border-bottom: 2px solid $primary;
  
      &:focus, &:hover {
        color: $primary;
        border-bottom: 2px solid $primary;
      }
    }
  
    > a {
      &:focus, &:hover {
        color: $primary;
        border-bottom: 2px solid $primary;
      }
    }
  }
  
  .main-profile-body .main-nav-line .nav-link.active::before {
    border: 1px solid $primary;
  }
  
  .main-nav-line .nav-link.active, .main-iconbar .nav-link.active {
    color: $primary;
  }
  
  .main-iconbar-logo::after {
    background-color: $primary;
  }
  
  .main-iconbar-body {
    .nav-item.active .nav-link {
      color: $primary;
  
      i {
        color: $primary;
      }
    }
  
    .nav-link {
      &:hover, &:focus, &:hover i, &:focus i {
        color: $primary;
      }
    }
  
    .nav-sub {
      .nav-sub-item.active > .nav-sub-link {
        color: $primary;
      }
  
      .nav-sub-link {
        &:hover, &:focus {
          color: $primary;
        }
      }
    }
  }
  
  .ui-datepicker .ui-datepicker-calendar .ui-datepicker-today a {
    background-color: $primary;
    color: $white;
  
    &:hover, &:focus {
      background-color: $primary;
      color: $white;
    }
  }
  
  .datetimepicker table {
    th {
      &.prev {
        &:hover span::before, &:focus span::before {
          color: $primary;
        }
      }
  
      &.next {
        &:hover span::before, &:focus span::before {
          color: $primary;
        }
      }
  
      &.switch {
        &:hover, &:focus {
          background-color: $white;
          color: $primary;
        }
      }
    }
  
    td.active {
      background-color: $primary;
      color: $white;
  
      &:hover, &:focus {
        background-color: $primary;
        color: $white;
      }
    }
  
    span.active {
      background-color: $primary;
      color: $white;
  
      &:hover, &:focus {
        background-color: $primary;
        color: $white;
      }
    }
  }
  
  .main-datetimepicker > .datepicker_inner_container > {
    .datepicker_calendar td.active, .datepicker_timelist > div.timelist_item.active {
      background-color: $primary;
      color: $white;
    }
  }
  
  .picker-picked {
    font-weight: 500;
    color: $primary;
  }
  
  .ql-editor {
    .ql-bg-blue {
      background-color: $primary;
    }
  
    .ql-color-blue {
      color: $primary;
    }
  }
  
  .ql-snow {
    &.ql-toolbar button:hover, .ql-toolbar button:hover, &.ql-toolbar button:focus, .ql-toolbar button:focus, &.ql-toolbar button.ql-active, .ql-toolbar button.ql-active, &.ql-toolbar .ql-picker-label:hover, .ql-toolbar .ql-picker-label:hover, &.ql-toolbar .ql-picker-label.ql-active, .ql-toolbar .ql-picker-label.ql-active, &.ql-toolbar .ql-picker-item:hover, .ql-toolbar .ql-picker-item:hover, &.ql-toolbar .ql-picker-item.ql-selected, .ql-toolbar .ql-picker-item.ql-selected {
      color: $primary;
    }
  
    &.ql-toolbar button:hover .ql-fill, .ql-toolbar button:hover .ql-fill, &.ql-toolbar button:focus .ql-fill, .ql-toolbar button:focus .ql-fill, &.ql-toolbar button.ql-active .ql-fill, .ql-toolbar button.ql-active .ql-fill, &.ql-toolbar .ql-picker-label:hover .ql-fill, .ql-toolbar .ql-picker-label:hover .ql-fill, &.ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-toolbar .ql-picker-label.ql-active .ql-fill, &.ql-toolbar .ql-picker-item:hover .ql-fill, .ql-toolbar .ql-picker-item:hover .ql-fill, &.ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-toolbar .ql-picker-item.ql-selected .ql-fill, &.ql-toolbar button:hover .ql-stroke.ql-fill, .ql-toolbar button:hover .ql-stroke.ql-fill, &.ql-toolbar button:focus .ql-stroke.ql-fill, .ql-toolbar button:focus .ql-stroke.ql-fill, &.ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-toolbar button.ql-active .ql-stroke.ql-fill, &.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, &.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, &.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, &.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill, .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
      fill: $primary;
    }
  
    &.ql-toolbar button:hover .ql-stroke, .ql-toolbar button:hover .ql-stroke, &.ql-toolbar button:focus .ql-stroke, .ql-toolbar button:focus .ql-stroke, &.ql-toolbar button.ql-active .ql-stroke, .ql-toolbar button.ql-active .ql-stroke, &.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-toolbar .ql-picker-label:hover .ql-stroke, &.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-toolbar .ql-picker-label.ql-active .ql-stroke, &.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-toolbar .ql-picker-item:hover .ql-stroke, &.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, &.ql-toolbar button:hover .ql-stroke-miter, .ql-toolbar button:hover .ql-stroke-miter, &.ql-toolbar button:focus .ql-stroke-miter, .ql-toolbar button:focus .ql-stroke-miter, &.ql-toolbar button.ql-active .ql-stroke-miter, .ql-toolbar button.ql-active .ql-stroke-miter, &.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, &.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, &.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, &.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
      stroke: $primary;
    }
  
    a {
      color: $primary;
    }
  }
  
  .irs-from, .irs-to, .irs-single {
    background-color: $primary;
  }
  
  .irs-bar, .irs-bar-edge {
    background-image: none;
    background-color: $primary;
  }
  
  .irs-from::after, .irs-to::after, .irs-single::after {
    border-top-color: $primary;
  }
  
  .irs-slider:before {
    background-color: $primary;
  }
  
  /* #################### COLOR VARIANT SKINS #################### */
  
  .irs-primary {
    .irs-bar, .irs-bar-edge, .irs-slider::before, .irs-from, .irs-to, .irs-single {
      background-color: $primary;
    }
  
    .irs-from::after, .irs-to::after, .irs-single::after {
      border-top-color: $primary;
    }
  }
  
  .irs-modern {
    .irs-slider {
      background-color: $white;
      border: 1px solid $primary;
  
      &::before {
        border-left: 1px solid $primary;
        border-right: 1px solid $primary;
      }
    }
  
    &.irs-primary .irs-slider {
      border-color: $primary;
  
      &::before {
        border-color: $primary;
      }
    }
  }
  
  .irs-outline {
    .irs-slider {
      border: 1px solid $primary;
  
      &:hover::before, &:focus::before {
        background-color: $primary;
      }
    }
  
    &.irs-primary {
      .irs-line, .irs-slider {
        border-color: $primary;
      }
    }
  }
  
  .select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: $primary;
  }
  
  .main-datepicker .ui-datepicker {
    .ui-datepicker-title {
      color: $primary;
    }
  
    .ui-datepicker-calendar .ui-datepicker-today a {
      background-color: $primary;
    }
  }
  
  .main-calendar {
    .fc-header-toolbar button.fc-today-button {
      color: $white;
      background-color: $primary;
      border-color: #003df2;
    }
  
    .fc-view {
      &.fc-agenda-view .fc-day-header.fc-today > a, &.fc-listMonth-view .fc-list-heading-main.now, &.fc-listWeek-view .fc-list-heading-main.now, &.fc-listMonth-view .fc-list-heading-main.now span:last-child, &.fc-listWeek-view .fc-list-heading-main.now span:last-child {
        color: $primary;
      }
    }
  
    td.fc-today .fc-day-number {
      background-color: $primary;
      color: $white;
  
      &:hover, &:focus {
        background-color: $primary;
        color: $white;
      }
    }
  }
  
  .main-nav-line-chat .nav-link.active {
    color: $primary;
  }
  
  .main-chat-contacts-more {
    background-color: $primary;
    color: $white;
  }
  
  .main-chat-list .media::after {
    background-color: $primary;
  }
  
  .main-chat-header .nav-link {
    &:hover, &:focus {
      color: $primary;
      background: #f3f6ff;
    }
  }
  
  .main-chat-footer .nav-link .nav-link {
    &:hover, &:focus {
      background: #f3f6ff;
      color: $primary;
    }
  }
  
  .main-contact-item.selected {
    z-index: 1;
    border-left-color: $primary;
    border-top-color: #e7effb;
    border-bottom-color: #e7effb;
    background-color: #e7effb;
  }
  
  .main-contact-info-header {
    .main-img-user a {
      &:hover, &:focus {
        background-color: $primary;
      }
    }
  
    .media-body .nav-link {
      &:hover, &:focus {
        color: $primary;
      }
    }
  }
  
  .main-contact-action a {
    &:hover, &:focus {
      color: $primary;
    }
  }
  
  .contact-tab .nav-link.active {
    color: $primary;
  }
  
  #contact-pagination a.active {
    background: $primary;
    color: $white;
  }
  
  .contact-table .page-item.current-page.active .page-link {
    &:hover, &:focus {
      background: $primary;
    }
  }
  
  @media (max-width: 991px) {
    .main-content-body-show .main-header-arrow {
      background: $primary;
      color: $white;
    }
  }
  
  .main-signin-header h2 {
    color: $primary;
  }
  
  .main-signin-footer a {
    &:hover, &:focus {
      color: $primary;
    }
  }
  
  .main-signup-header h2 {
    color: $primary;
  }
  
  .main-signup-footer a {
    &:hover, &:focus {
      color: $primary;
    }
  }
  
  .main-invoice-list {
    .media-body h6 span:last-child {
      color: $primary;
    }
  
    .selected {
      background-color: #f6f9fd;
      border-top-color: #f9eded;
      border-bottom-color: #f9eded;
      border-left-color: $primary;
    }
  }
  
  .main-content-body-profile .main-nav-line .nav-link.active {
    color: $primary;
  
    &::before {
      background-color: $primary;
    }
  }
  
  .account-setting-tab-menu .list-group > a {
    .glyphicon, .fa {
      color: $primary;
    }
  
    &.active {
      background-color: #e7effb;
      color: $primary;
      border-color: #e7effb;
      border-left: 2px solid $primary;
    }
  }
  
  .pricingTable {
    &:after {
      background: $primary;
    }
  
    .icon {
      color: $primary;
    }
  
    .price-value {
      border-bottom: 5px solid $primary;
    }
  
    .pricingTable-signup {
      background: $primary;
    }
  
    &:hover .pricingTable-signup {
      background: $white;
      color: $primary;
    }
  
    .pricingTable-signup {
      &:before {
        border-right: 12px solid $primary;
      }
  
      &:after {
        border-left: 12px solid $primary;
      }
    }
  }
  
  .pricingTable1 {
    .price-value {
      background: $primary;
    }
  
    .pricingTable1-signup {
      background: $primary;
      color: $white;
      border: 2px solid $primary;
  
      &:hover {
        background: $white;
        color: $primary;
      }
    }
  }
  
  .pricingTable3:hover {
    .pricingTable3-header {
      background: $primary;
      color: $white;
    }
  
    .btn {
      background: $primary;
      color: $white;
      border: 1px solid $primary;
    }
  }
  
  @media screen and (min-width: 1450px) {
    .pricingTable3 .price-value {
      background: $primary;
    }
  }
  
  @media screen and (max-width: 1449px) {
    .pricingTable3 .price-value {
      background: $primary;
      color: $white;
    }
  }
  
  .pricing-tabs ul.nav-price li a.active {
    background: $primary;
    color: $white;
  }
  
  .button-backlog {
    color: $primary;
  }
  
  .scrum-board-item:before {
    background: $primary;
  }
  
  .scrum-board-card .btn-primary {
    color: $primary;
  }
  
  .task-status .status-backlog {
    background-color: $primary;
  }
  
  .product-list .social li a:hover {
    color: $white;
    background: $primary;
    border: 1px solid $primary;
  }
  
  .item2-gl-menu a.active, .product-list h5 a:hover, .product-grid h3 a:hover, .list-inline-dotted {
    color: $primary;
  }
  
  .product-grid {
    .social li a:hover {
      color: $white;
      background: $primary;
      border: 1px solid $primary;
    }
  
    .title a:hover {
      color: $primary;
    }
  }
  
  .adtocart {
    background: $primary;
  }
  
  .item2-gl-menu .box.open i {
    background: $primary;
    color: $white !important;
    border: 1px solid $primary;
  }
  
  .bg-indigo {
    background-color: $primary;
  }
  
  .bg-primary-transparent, .bg-primary-transparent-2 {
    background-color: rgba(0, 104, 255, 0.15);
  }
  
  /* ###### 4.8 Box-shadows ###### */
  
  .box-shadow-primary {
    box-shadow: 0 5px 10px rgba(0, 104, 255, 0.25);
  }
  
  .border-primary, .bd-primary {
    border-color: $primary !important;
  }
  
  .tx-primary {
    color: $primary;
  }
  
  .tag-indigo {
    background-color: $primary;
    color: $white;
  }
  
  .tabs-style-6 .nav.panel-tabs li a.active {
    background: $primary;
    color: $white;
  
    &:hover, &:focus {
      background: $primary;
      color: $white;
    }
  }
  
  .tabs-style-9 .nav.panel-tabs li a.active {
    background: $primary;
    color: $white;
  
    &:hover, &:focus {
      background: $primary;
      color: $white;
    }
  }
  
  .tabs-style-7 .nav.panel-tabs li a.active {
    &:after {
      content: "";
      border-top: 10px solid $primary;
    }
  
    color: $white;
    background: $primary;
    border-right: 1px solid #dfe6f9;
  
    &:hover {
      color: $white;
      background: $primary;
      border-right: 1px solid #dfe6f9;
    }
  }
  
  .tabs-style-8 .nav.panel-tabs li a.active {
    &:before {
      border-top: 3px solid $primary;
    }
  
    &:after {
      border-top: 7px solid $primary;
    }
  }
  
  .main-navbar {
    .nav-item.active .nav-link, .nav-sub-item.active > .nav-sub-link {
      color: $primary;
    }
  
    .nav-sub-link {
      &:hover, &:focus {
        color: $primary;
        outline: none;
      }
    }
  }
  
  @media (min-width: 992px) {
    .main-navbar-two .nav-item.active::before {
      border-bottom: 2px solid $primary;
    }
  }
  
  .main-navbar-three .nav-item.active .nav-link {
    color: $primary;
  }
  
  .wizard > {
    .steps .current a {
      color: $primary;
  
      &:hover, &:active {
        color: $primary;
      }
  
      .number, &:hover .number, &:active .number {
        background-color: $primary;
      }
    }
  
    .actions a {
      background-color: $primary;
  
      &:hover, &:active {
        background-color: $primary;
        color: $white;
      }
    }
  }
  
  .wizard-style-1 > .steps > ul .current a {
    .number, &:hover .number, &:active .number {
      background-color: $primary;
      color: $white;
    }
  }
  
  .wizard-style-2 > .steps > ul .current a {
    .number, &:hover .number, &:active .number {
      border-color: $primary;
      color: $primary;
    }
  }
  
  .rdiobox input[type='radio']:checked + span:before {
    border-color: transparent;
    background-color: $primary;
  }
  
  .br-theme-bars-horizontal .br-widget {
    a {
      &.br-active, &.br-selected {
        background-color: $primary;
      }
    }
  
    .br-current-rating {
      color: $primary;
    }
  }
  
  .br-theme-bars-pill .br-widget a {
    background-color: #e5eafb;
    color: $primary;
  
    &.br-active, &.br-selected {
      background-color: $primary;
      color: white;
    }
  }
  
  .br-theme-bars-square .br-widget a {
    &.br-active, &.br-selected {
      border: 2px solid #e5eafb;
      color: $primary;
    }
  }
  
  .br-theme-bars-movie .br-widget {
    a {
      &.br-active, &.br-selected {
        background-color: $primary;
      }
    }
  
    .br-current-rating {
      color: $primary;
    }
  }
  
  .br-theme-bars-1to10 .br-widget {
    a {
      &.br-active, &.br-selected {
        background-color: $primary;
      }
    }
  
    .br-current-rating {
      color: $primary;
    }
  }
  
  .br-theme-fontawesome-stars .br-widget a {
    &.br-active:after, &.br-selected:after {
      color: $primary;
    }
  }
  
  .timeline-wrapper-primary {
    .timeline-panel:before, .timeline-badge {
      background: $primary;
    }
  }
  
  #count-down .clock-presenter:before {
    background: $primary;
  }
  
  .ckbox span:after, .main-toggle.on, .main-toggle-secondary.on {
    background-color: $primary;
  }
  
  .list-group-item.active {
    color: $white;
    background-color: $primary;
    border-color: $primary;
  }
  
  .custom-control-input {
    &:checked ~ .custom-control-label::before {
      color: $white;
      border-color: $primary;
      background-color: $primary;
    }
  
    &:focus ~ .custom-control-label::before {
      box-shadow: 0 0 0 0.2rem rgba(0, 104, 255, 0.25);
    }
  }
  
  .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
    border-color: $primary;
    background-color: $primary;
  }
  
  .custom-range {
    &::-webkit-slider-thumb, &::-moz-range-thumb, &::-ms-thumb {
      background-color: $primary;
    }
  }
  
  .custom-checkbox .custom-control-input {
    &:checked ~ .custom-control-label::before, &:indeterminate ~ .custom-control-label::before, &:disabled:checked ~ .custom-control-label::before {
      background-color: $primary;
    }
  }
  
  .custom-radio .custom-control-input {
    &:checked ~ .custom-control-label::before, &:disabled:checked ~ .custom-control-label::before {
      background-color: $primary;
    }
  }
  
  .custom-select:focus {
    border-color: $primary;
  }
  
  .custom-file-label::after {
    background-color: $primary;
    border-left: 1px solid $primary;
  }
  
  .custom-range {
    &::-webkit-slider-thumb, &::-moz-range-thumb, &::-ms-thumb {
      background-color: $primary;
    }
  }
  
  .selectgroup-input {
    &:checked + .selectgroup-button {
      border-color: $primary;
      color: $primary;
      background: #edf2fa;
    }
  
    &:focus + .selectgroup-button {
      border-color: $primary;
      color: $primary;
      box-shadow: 0 0 0 2px rgba(0, 104, 255, 0.25);
    }
  }
  
  .custom-switch-input {
    &:checked ~ .custom-switch-indicator {
      background: $primary;
    }
  
    &:focus ~ .custom-switch-indicator {
      box-shadow: 0 0 0 2px rgba(0, 104, 255, 0.25);
      border-color: $primary;
    }
  }
  
  .label-primary {
    background: $primary;
  }
  
  .main-profile-menu .dropdown-item {
    &:hover, &:focus {
      background-color: transparent;
      color: $primary;
    }
  }
  
  .mega-dropdown-menu .dropdown-item:hover {
    color: $primary;
    background: transparent;
  }
  
  .notification-label:hover, .main-message-list .name:hover, .page-header-icon i {
    color: $primary;
  }
  
  .line-list li:before {
    border: 3px solid $primary;
    background: $white;
  }
  
  .main-sidebar-body .nav-sub .nav-sub-item .nav-sub-link:before::hover {
    background: $primary;
  }
  
  .latest-tasks {
    .task-line.primary:before {
      background: $primary;
    }
  
    .nav-tabs .nav-link {
      &.active, &:hover, &:focus {
        color: $primary;
      }
    }
  }
  
  .project-countdown .countdown li {
    background: $primary;
    border: 5px solid $primary;
  }
  
  .activity .added-project {
    color: $primary;
  }
  
  .project-card {
    &:hover i {
      color: $primary;
    }
  
    .primary {
      fill: $primary;
    }
  
    &:hover .primary {
      fill: $primary;
      opacity: 1;
    }
  }
  
  .card-minimal-two .nav-pills .nav-link.active {
    background-color: $primary;
  }
  
  .task-box.primary {
    background-color: rgba(0, 104, 255, 0.6) !important;
    color: $primary !important;
    border: 1px dashed #7da7e4;
  }
  
  .dataTables_paginate .page-item.active .page-link {
    background-color: $primary;
  }
  
  .dataTables_wrapper .dataTables_paginate .paginate_button.focus {
    background: $primary;
  }
  
  .border-left-primary {
    border-left-color: $primary !important;
  }
  
  .preview-thumbnail.nav-tabs li a.active img {
    border: 2px solid $primary !important;
  }
  
  .avatar {
    background-color: $primary;
  }
  
  .sidebar-right .main-nav-line .nav-link.active {
    color: $primary;
  }
  
  .side-menu__item.slide-item {
    &.active, &:hover, &:focus {
      color: $primary;
    }
  
    &.active:after {
      background: $primary;
    }
  }
  
  .slide-menu a.active {
    color: $primary;
  }
  
  .bg-image:before, .bg-image1 {
    background: $primary;
  }
  
  .resp-vtabs li.active .social p:hover {
    color: $primary;
  }
  
  /*-----Back to Top-----*/
  
  #back-to-top {
    background: $primary;
    color: $white;
    border: 1px solid #dedcfb;
  
    &:hover {
      background: $primary;
      color: $white;
      border: 1px solid $white;
    }
  }
  
  .card.offer-card.sales-card:before, .upgrade:before, .tabs-style-1 .main-nav-line .nav-link.active::before {
    background: $primary;
  }
  
  .tabs-style-2 .main-nav-line .nav-link.active, .tabs-style-3 .nav.panel-tabs li a.active, .tabs-style-4 .nav.panel-tabs li a.active {
    background: $primary;
    color: $white;
  }
  
  .tab_wrapper {
    > ul li.active {
      border-color: $primary;
      background: $primary;
      color: $white;
    }
  
    .content_wrapper .accordian_header.active {
      color: $primary;
  
      &:after {
        background: $primary;
      }
    }
  }
  
  .items-blog-tab-menu li a {
    &.active, &:hover {
      color: $primary;
    }
  }
  
  .panel-title1 a {
    border-left: 2px solid $primary;
  
    &.collapsed {
      border-left: 3px solid $primary;
    }
  }
  
  .widget-header:before {
    background: $primary;
  }
  
  .fill-primary {
    fill: $primary;
  }
  
  .main-content-body-show .main-header-mail-arrow {
    &:hover {
      color: $primary;
    }
  
    color: $primary;
  }
  
  .btn-search {
    padding: 8px 12px;
  }
  
  .main-footer a, .main-star-item {
    color: $primary;
  }
  
  .testbgpattern1:before {
    background-color: $primary;
  }
  
  .invoiceicon, .invoiceicon-lg {
    color: $primary;
  }
  
  .bg-background2:before {
    background: linear-gradient(-225deg, $primary 45%, $primary 100%) !important;
  }
  
  .conatct-list .media-icon, .statistics .media-icon, .statistics2 .media-icon {
    color: $primary;
  }
  
  .chart-icon .text-primary {
    color: $primary !important;
  }
  
  .SumoSelect > .optWrapper.multiple > .MultiControls > p.btnOk:focus {
    background: $primary;
    color: $white;
  }
  
  .datepicker-panel > ul > li {
    &.highlighted {
      background-color: $primary !important;
      color: $white !important;
  
      &:hover {
        background-color: $primary !important;
        color: $white !important;
      }
    }
  
    &.picked {
      background-color: rgba(0, 104, 255, 0.1);
      color: $primary;
  
      &:hover {
        background-color: rgba(0, 104, 255, 0.1);
        color: $primary;
      }
    }
  }
  
  .step-app > .step-steps > li.active a {
    background: $primary;
    border-color: $primary;
  }
  
  .lg-actions {
    .lg-next, .lg-prev {
      background-color: $primary;
      border: 1px solid $primary;
    }
  }
  
  .lg-outer .lg-thumb-item {
    &.active, &:hover {
      border-color: $primary;
    }
  }
  
  .SumoSelect {
    > .optWrapper.multiple {
      &.okCancelInMulti > .MultiControls > p:focus {
        border-color: $primary;
        background-color: $primary;
      }
  
      > .options li.opt.selected span i {
        background-color: $primary;
      }
    }
  
    .select-all {
      &.selected > span i, &.partial > span i {
        background-color: $primary;
      }
    }
  }
  
  .step-app .whishlist {
    fill: $primary !important;
  }
  
  .horizontal-dark .hor-menu .horizontalMenu > .horizontalMenu-list > li > a {
    &.active, &:hover {
      color: $primary;
    }
  }
  
  .horizontal-color .horizontal-main.hor-menu {
    background: $primary;
  }
  
  .hor-menu .horizontalMenu > .horizontalMenu-list > li {
    > a.active, &:hover .sub-icon {
      color: $primary;
    }
  
    > {
      a.active {
        color: $primary;
        color: $primary;
      }
  
      ul.sub-menu > li > a {
        &.active, &:hover {
          color: $primary;
        }
      }
    }
  }
  
  .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu > li > a:hover {
    color: $primary;
  }
  
  .hor-menu {
    .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list li:hover a {
      color: $primary;
    }
  
    .slide-item {
      &.active, &:hover, &:focus {
        color: $primary !important;
      }
    }
  }
  
  .scrumb-card .btn-primary {
    &:hover, &:focus {
      color: $primary !important;
    }
  
    color: $primary !important;
  }
  
  .bootstrap-tagsinput .badge {
    background-color: $primary !important;
    border: 1px solid $primary !important;
  }
  
  .sweet-alert button {
    background-color: $primary !important;
  }
  
  @media (min-width: 576px) {
    .main-header-right .dropdown-menu:after {
      border-bottom: 9px solid $primary;
    }
  }
  
  
.slide.is-expanded .side-menu__label {
    color: $primary;
}
slide.is-expanded .side-menu .side-menu__icon {
  background: $primary;
	color: $white;

  &:hover, &:focus {
    background: $primary;
	color: $white;
  }
}
.side-menu .slide.active {
  .side-menu__label, .side-menu__icon {
    color: $primary;
  }
}
.side-menu__item {
	&.active, &:hover, &:focus {
		text-decoration: none;
		color: $primary;
	}
	&.active .side-menu__label{
	   color: $primary;
	}
	&.active .angle{
	   color: $primary;
	}
	&.active .side-menu__icon{
	   fill: $primary;
	}

	&.active .side-menu__icon, &:hover .side-menu__icon, &:focus .side-menu__icon {
		background: $primary;
		color:$white !important;
	}
}
slide {
  &:hover {
    .side-menu__label, .angle {
      color: $primary!important;
      fill: $primary!important;
    }
 }
}
.slide-item {
	&.active, &:hover, &:focus {
    text-decoration: none;
    color: $primary!important;
  }
}
.sidebar-scroll .slide-item.active:before {
	background: $primary;
}
.sub-slide.is-expanded {
  .sub-side-menu__item {
    color: $primary;
  }
}
.app-sidebar {
	.slide {
    &.active .side-menu__icon, &.is-expanded .side-menu__icon {
      background: $primary;
		color: $white;
    }

    &.active {
      .side-menu__icon {
       background: $primary;
		color: $white;
      }
    }
  }
}	

.app-sidebar .slide-menu a.active:before{
	color: $primary;
}